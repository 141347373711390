import { Route, Routes } from "react-router-dom"
import { lazy } from "react"
import ForgotPassword from "components/Authentication/Forgot Password/ForgotPassword"
import LockScreen from "components/Authentication/LockScreen/LockScreen"
import Login from "components/Authentication/Login/Login"
import Register from "components/Authentication/Register/Register"
import Error400 from "components/Authentication/errorPage/Error400/Error400"
import Error401 from "components/Authentication/errorPage/Error401/Error401"
import Error403 from "components/Authentication/errorPage/Error403/Error403"
import Error404 from "components/Authentication/errorPage/Error404/Error404"
import Error500 from "components/Authentication/errorPage/Error500/Error500"
import Error503 from "components/Authentication/errorPage/Error503/Error503"
import AuthLogin from "components/Authentication/firebaseAuth/AuthLogin"
import SignUp from "components/Authentication/firebaseAuth/Signup"
import AuthenticationPage from "components/AuthenticationPage"
import CustomPage from "components/CustomPage"
import ErrorPages from "components/ErrorPages"
import UnderConstruction from "components/pages/Extension/UnderConstruction/UnderConstruction"
import HabitsGeneral from "pages/contentInteraction/habitsGeneral/HabitsGeneral"
import InterestsGeneral from "pages/contentInteraction/interestsGeneral/InterestsGeneral"
import Productivity from "pages/productivity/Productivity"
import SpaceInteraction from "pages/spaceInteraction/SpaceInteraction"
import Auth from 'components/Authentication/firebaseAuth/auth';
import PrivateRoute from "./routesHandler"
import { ExportData } from "pages/contentInteraction/exportData/ExportData"
const App = lazy(() => import('../components/app'));

const Router = () => {

    return (
        <Routes>
            <Route path={`${process.env.PUBLIC_URL}/`} element={<Auth />}>
                <Route index element={<AuthLogin />} />
                <Route path={`${process.env.PUBLIC_URL}/Authentication/firebaseAuth/login`} element={<AuthLogin />} />
                <Route path={`${process.env.PUBLIC_URL}/Authentication/firebaseAuth/SignUp`} element={<SignUp />} />
            </Route>
            <Route path={`${process.env.PUBLIC_URL}/`} element={<PrivateRoute render={App} />}>
                <Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<PrivateRoute render={Productivity} />} />
                <Route>
                    <Route path={`${process.env.PUBLIC_URL}/habits/general`} element={<PrivateRoute render={HabitsGeneral} />} />
                    <Route path={`${process.env.PUBLIC_URL}/interests/general`} element={<PrivateRoute render={InterestsGeneral} />} />
                    <Route path={`${process.env.PUBLIC_URL}/export/data`} element={<PrivateRoute render={ExportData} />} />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/spaceInteraction`} element={<PrivateRoute render={SpaceInteraction} />} />
                <Route path="*" element={<UnderConstruction />} />
            </Route>

            {/* Authentication Pages */}
            <Route path={`${process.env.PUBLIC_URL}/`} element={<AuthenticationPage />}>
                <Route path={`${process.env.PUBLIC_URL}/authentication/login`} element={<Login />} />
                <Route path={`${process.env.PUBLIC_URL}/authentication/register`} element={<Register />} />
                <Route path={`${process.env.PUBLIC_URL}/authentication/forgotpassword`} element={<ForgotPassword />} />
                <Route path={`${process.env.PUBLIC_URL}/authentication/lockscreen`} element={<LockScreen />} />,
            </Route>

            {/* Custom Pages for UnderConstruction */}

            <Route path={`${process.env.PUBLIC_URL}/`} element={<CustomPage />}>
                <Route path={`${process.env.PUBLIC_URL}/pages/extension/underconstruction`} element={<UnderConstruction />} />
            </Route>

            {/* Authentication Error Pages */}
            <Route path={`${process.env.PUBLIC_URL}`} element={<ErrorPages />}>
                <Route path={`${process.env.PUBLIC_URL}/authentication/errorpage/error400`} element={<Error400 />} />
                <Route path={`${process.env.PUBLIC_URL}/authentication/errorpage/error401`} element={<Error401 />} />
                <Route path={`${process.env.PUBLIC_URL}/authentication/errorpage/error403`} element={<Error403 />} />
                <Route path={`${process.env.PUBLIC_URL}/authentication/errorpage/error404`} element={<Error404 />} />
                <Route path={`${process.env.PUBLIC_URL}/authentication/errorpage/error500`} element={<Error500 />} />
                <Route path={`${process.env.PUBLIC_URL}/authentication/errorpage/error503`} element={<Error503 />} />,
            </Route>
            {/* Firebase Authentication */}

        </Routes>
    )
}

export default Router
