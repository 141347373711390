import React from 'react';
import styles from './PageHeader.module.scss';
import { faHourglass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSelector } from 'react-redux';
import { State } from 'store/reducers';
import imagePDF from 'assets/images/download-pdf.png'
import imageXLSX from 'assets/images/download-xlsx.png'

const PageHeader = (props: any) => {

  const { downloadPDF, hideExport, XMLHandler, disablePDF } = props;
  const isLoadingPdf = useSelector((state: State) => state.isLoadingPdf);

  const handlerDownloadXML = (data) => {
    if (XMLHandler) {
      XMLHandler();
    } else {
      downloadPDF(data)
    }
  }
  return (
    <div className={styles.PageHeader}>
      {/* <!-- PAGE-HEADER --> */}
      <div className="page-header">
        <h1 className="page-title">{props.titles}</h1>
        {!hideExport && <fieldset className="d-inline-flex">
          <legend>Exportar</legend>
          <button disabled={!XMLHandler} style={{ marginRight: 10 }} onClick={() => handlerDownloadXML(props.data)} className={`input-group-text input-group-text button-export-heade ${!XMLHandler && 'disabled'}`}>
            {isLoadingPdf ? <div style={{ padding: '2px 63px', fontSize: 25 }}><FontAwesomeIcon className='fa-spin-header' style={{ color: 'white' }} icon={faHourglass} /> </div> : <><div style={{ fontSize: 14, fontWeight: 700, }}>XLSX <img style={{filter: !XMLHandler ? 'grayscale(100%)': 'none'}} src={imageXLSX} width={25} alt="" /></div></>}
          </button>
          <button disabled={disablePDF} onClick={() => downloadPDF(props.data)} className={`input-group-text input-group-text button-export-heade ${disablePDF && 'disabled'}`}>
            {isLoadingPdf ? <div style={{ padding: '2px 63px', fontSize: 25 }}><FontAwesomeIcon className='fa-spin-header' style={{ color: 'white' }} icon={faHourglass} /> </div> : <><div style={{ fontSize: 14, fontWeight: 700, }}>PDF <img src={imagePDF} width={35} alt="" /></div></>}
          </button>
        </fieldset>}
      </div>
      {/* <!-- PAGE-HEADER END --> */}
    </div>
  )
};

export default PageHeader;
