import { getAvgTimeConsumedArtVisitor } from "services/api"
import { TFetchRequest } from "pages/productivity/types/TServicesExploitation"
import { TRequestAvgTimeConsumedArtVisitor } from "../types/TServicesExploitation"

export const RequestAvgTimeConsumedArtVisitor = (): TFetchRequest =>  {
    const defaultData: TRequestAvgTimeConsumedArtVisitor = {
        averageTimeConsumed: 0,
    };

    return {request: getAvgTimeConsumedArtVisitor, defaultData}
}