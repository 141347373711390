import { UsersAuth } from 'Data/constant';
import React, { useEffect, useRef, useState } from 'react';
import { Form, Alert, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { getLanguagesByMuseum, getMuseum, getRoutesByMuseum } from 'services/api';
import { ActionType, State, actionCreators } from 'store/index';

const SignIn = () => {
  const setInputFocus = () => {
    focus.current?.focus();
  }
  const [err, setError] = useState("");
  const [loading, setLoader] = useState(false);
  const [data, setData] = useState({
    "email": "",
    "password": "",//mm_va_231
  })
  const focus = useRef<HTMLInputElement>(null);
  const { email, password } = data;
  const changeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
    setError("");
  }
  const dispatch = useDispatch();
  const {
    updateIsAuthenticated,
    updateFilterInitial,
    updateRole,
    updateUser
  } = bindActionCreators(actionCreators, dispatch);
  const isAuthenticated: boolean = useSelector((state: State) => state.isAuthenticated);
  const museumList: any = useSelector((state: State) => state.museumList);

  const Login: any = async (e) => {
    setLoader(true)
    e.preventDefault();
    try {
      const user = UsersAuth.find(e => e.user === email);
      if (password === user?.pass) {
        console.log('User Accept'); RouteChange(); setLoader(false);
        let responseMuseum = museumList;
        if (museumList.length === 0) {
          responseMuseum = await getMuseum();
          dispatch({
            type: ActionType.UPDATE_MUSEUM,
            payload: responseMuseum,
          });
        }

        const responseLanguages = await getLanguagesByMuseum(user.idMuseum || responseMuseum[0].id);
        dispatch({
          type: ActionType.UPDATE_LANGUAGES,
          payload: responseLanguages,
        });
        const responseRoutes = await getRoutesByMuseum(user.idMuseum || responseMuseum[0].id);
        dispatch({
          type: ActionType.UPDATE_ROUTES,
          payload: responseRoutes,
        });
        updateRole(user.role);
        updateFilterInitial({
          museum: user.role === 'ADMIN' ? responseMuseum[0].id : user.idMuseum,
          languages: responseLanguages,
          routes: responseRoutes
        });

        updateUser({ email: user.user });
        updateIsAuthenticated(true);
      } else {
        setData({
          "email": "",
          "password": "",//mm_va_231
        });
        setInputFocus();
        throw new Error("error");
      }
    } catch (error) {
      console.log(error); setError("Error autenticación"); setLoader(false)
    }

    // auth.signInWithEmailAndPassword(email, password).then(
    //   user =>{console.log(user); RouteChange(); setLoader(false)}).catch(err => { console.log(err);  setError(err.message); setLoader(false) })
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      Login(event);
    }
  }
  let navigate = useNavigate();
  const RouteChange = () => {
    let path = `${process.env.PUBLIC_URL}/dashboard/`;
    navigate(path);
  }

  useEffect(() => {
    if (isAuthenticated)
      navigate(`${process.env.PUBLIC_URL}/dashboard/`);
    setInputFocus();
  }, [isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className='login-img'>
        <div className="page">
          {/* <!-- CONTAINER OPEN --> */}
          <div className="col-login mx-auto mt-7">
            <div className="text-center">
              <img src={require("../../../assets/images/brand/logo-b-4.png")} className="header-brand-img" alt="" />
            </div>
          </div>
          <div className="container-login100">
            <div className="wrap-login100 p-6">
              <form className="login100-form validate-form">
                <span className="login100-form-title pb-5"> Login</span>
                <div>
                  {err && <Alert variant="danger">{err}</Alert>}
                  <div className="wrap-input100 validate-input input-group">
                    <Link tabIndex={-1} to="#" className="input-group-text bg-white text-muted">
                      <i className="zmdi zmdi-email text-muted" aria-hidden="true"></i>
                    </Link>
                    <Form.Control ref={focus} className="input100 border-start-0 form-control ms-0" type="email" name="email" placeholder="Email" value={email}
                      onChange={changeHandler} required />{" "}
                  </div>

                  <InputGroup onKeyDown={e => handleKeyDown(e)} className="wrap-input100 validate-input" id="Password-toggle">
                    <InputGroup.Text id="basic-addon2" className="bg-white text-muted">
                      <Link tabIndex={-1} to='#'><i className="zmdi zmdi-eye text-default" aria-hidden="true" ></i></Link>
                    </InputGroup.Text>
                    <Form.Control className="input100 border-start-0 ms-0" type='password' name="password" placeholder="Password" value={password}
                      onChange={changeHandler} required />{" "}
                  </InputGroup>
                  <div className="container-login100-form-btn">
                    <Link to='#' onClick={Login} className="login100-form-btn btn-primary">
                      Login
                      {loading ? <span role="status" aria-hidden="true" className="spinner-border spinner-border-sm ms-2"></span> : ""}
                    </Link>
                  </div>
                  <div className="text-center pt-3">

                  </div>
                  {/* <label className="login-social-icon"><span>Login with Social</span></label>
                  <div className="d-flex justify-content-center">
                    <Link to="#">
                      <div className="social-login me-4 text-center">
                        <i className="fa fa-google"></i>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="social-login me-4 text-center">
                        <i className="fa fa-facebook"></i>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="social-login text-center">
                        <i className="fa fa-twitter"></i>
                      </div>
                    </Link>

                  </div>
                  <Link to={`${process.env.PUBLIC_URL}/Authentication/firebaseAuth/SignUp`} className='d-flex justify-content-center mt-4'>
                    Create a new account ?
                  </Link> */}

                </div>
              </form>
            </div>
          </div>
          {/* // <!-- CONTAINER CLOSED --> */}
        </div>
      </div>
    </div >

  );
}

SignIn.propTypes = {};

SignIn.defaultProps = {};

export default SignIn;
