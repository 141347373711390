import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { actionCreators } from 'store';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';

export const handleGeneratePdfAllPage = async (dispatch: Dispatch<AnyAction>) => {
    const {
        updateIsLoadingPdf
    } = bindActionCreators(actionCreators, dispatch);
   try {
    updateIsLoadingPdf(true);
    const data1: any = document.getElementById("graphic-interestGeneral-1");
    
    const imgWidth = 208;


    let canvas1 = await html2canvas(data1, { backgroundColor: '#2A2A4A',  allowTaint:true, useCORS: true, });

    const imgHeight = canvas1.height * imgWidth / canvas1.width;

    let doc = new jsPDF('p', 'mm', 'letter');

    const mm1 = canvas1.toDataURL('image/png');

    doc.text('Interteses Generales', 80, 15);

    doc.addImage(mm1, 'PNG', 4, 30, imgWidth, imgHeight)

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    doc.save(`Productividad_${mm + '/' + dd + '/' + yyyy}.pdf`);
   
   } catch (error) {
        console.log('Error Download PDF', error);
   } finally {
    updateIsLoadingPdf(false);
   }
    
};