export enum ActionType {
  UPDATE_IS_AUTHENTICATED = 'update_is_authenticated',
  UPDATE_IS_LOADING = 'update_is_loading',
  UPDATE_USER = 'update_user',
  UPDATE_FILTER = 'update_filter',
  UPDATE_IS_LOADING_PDF = 'update_is_loading_pdf',
  UPDATE_MUSEUM = 'update_museum',
  UPDATE_FILTER_MUSEUM = 'update_filter_museum',
  UPDATE_LANGUAGES = 'update_languages',
  UPDATE_ROLE = 'update_role',
  UPDATE_ROUTES = 'update_routes',
  UPDATE_FILTER_INITIAL = 'update_filter_initial',
}

export enum ActionTypeSaga {
  LISTEN_EXAM_SETTING_FILL = 'listenExamSetting',
  LISTEN_EXAM_SETTING_UPDATE = 'listenExamSettingUpdate',
  GET_EXAMS = 'get_exams',
}
