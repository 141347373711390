import axios from "../axios";

export const getAvgTimeConsumedArtVisitor = ({params, cancelTokenSource}) => {

  return axios
    .get(`/average_time_consumed_artwork_visitor`, {
      cancelToken: cancelTokenSource,
      params
    })
    .then(({ data }) => Promise.resolve(data))
    // .then(({ data }) => {
    //   const dataParsed = parseDataTotalHours(data);
    //   return dataParsed;
    // } )
    .catch((error) => {
      return Promise.reject(error);
    });
};
