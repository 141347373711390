import axios from "../axios";

export const getLanguagesByMuseum = (idMuseum) => {
  return axios
    .get(`/languages/${idMuseum}`)
    .then(({ data }) => Promise.resolve(data["hydra:member"]))
    .catch((error) => {
      return Promise.reject(error);
    });
};
