import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers/index'
import rootSaga from './sagas';
import { persistStore } from 'redux-persist'

const sagaMiddleware = createSagaMiddleware()
export const store = configureStore({
  reducer: reducers,
  middleware: [...getDefaultMiddleware({ immutableCheck: false,
    serializableCheck: false }), sagaMiddleware ],
  devTools: process.env.REACT_APP_ENVIRONMENT === 'dev'? true: false
});


export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga)
