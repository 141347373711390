import React from 'react';
import styles from './scss/Dashboard.module.scss';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import { Card, Col, Row } from 'react-bootstrap';
import { TotalUser, TotalUserPDF } from '../../Data/DataDashBoard/DashBoardData';
import { TComponentUX } from 'pages/productivity/types/TServicesExploitation'
import { BarChart } from './components/BarChart';
import { DonutApexChart } from './components/DonutApexChart'
import { DnaLoader } from 'components/loaders/DnaLoader';
import { FilterSelector } from "components/filters/Filter";
import { BarChartPDF } from './components/BarChartPDF';

const ProductivityUX: React.FC<TComponentUX> = ({ getVisitByUsersData, getVisitByHour, downloadPDF }) => {

    return (
        <div className={styles.Dashboard}>
            <PageHeader titles="Productividad" active="Dashboard" items={['Home']} downloadPDF={downloadPDF} data={{ data1: getVisitByUsersData.data, data2: getVisitByHour.data }} />
            {/* <!-- ROW-1 --> */}
            <FilterSelector /> <br />
            <Row>
                <Col lg={12} md={12} sm={12} xl={12}>
                    <Row>
                        <Col lg={6} md={6} sm={12} xl={4}>
                            <Card className="card overflow-hidden">
                                {getVisitByUsersData.loading ?
                                    <DnaLoader /> :
                                    <>
                                        <Card.Body>
                                            <div className="d-flex" >
                                                <div className="mt-2">
                                                    <h6 className="">Total Usuarios</h6>
                                                    <h2 className="mb-0 number-font">{getVisitByUsersData.data.all.toLocaleString("de", {minimumFractionDigits: 0, maximumFractionDigits: 2})}</h2>
                                                </div>
                                                <div className="ms-auto">
                                                    <div className="chart-wrapper mt-1 p-1">
                                                        <TotalUser />
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </>}
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12} xl={4}>
                            <div className="card overflow-hidden">
                                {getVisitByUsersData.loading ?
                                    <DnaLoader /> :
                                    <>
                                        <Card.Body>
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className="">Usuarios Smart Guide</h6>
                                                    <h2 className="mb-0 number-font">{getVisitByUsersData.data.smartGuide.toLocaleString("de", {minimumFractionDigits: 0, maximumFractionDigits: 2})}</h2>
                                                </div>
                                                <div className="ms-auto">
                                                    <div className="chart-wrapper mt-1">
                                                        <DonutApexChart data={[getVisitByUsersData.data.easyGuide, getVisitByUsersData.data.smartGuide]} type="easy" width={110} height={80} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </>}
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xl={4}>
                            <div className="card overflow-hidden">
                                {getVisitByUsersData.loading ? <DnaLoader /> :
                                    <Card.Body>
                                        <div className="d-flex">
                                            <div className="mt-2">
                                                <h6 className="">Usuarios Easy Guide</h6>
                                                <h2 className="mb-0 number-font">{getVisitByUsersData.data.easyGuide.toLocaleString("de", {minimumFractionDigits: 0, maximumFractionDigits: 2})}</h2>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="chart-wrapper mt-1">
                                                    <DonutApexChart data={[getVisitByUsersData.data.smartGuide, getVisitByUsersData.data.easyGuide]} type="smart" width={110} height={80} />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* <!-- ROW-1 END --> */}

            {/* <!-- ROW-2 --> */}
            <Row>

                <Col sm={12} md={12} lg={12} xl={6}>
                    <Card>
                        {getVisitByUsersData.loading ?
                            <DnaLoader /> :
                            <>
                                <div>
                                    <Card.Header>
                                        <Card.Title as="h3">Usuarios por Herramientas</Card.Title>
                                    </Card.Header>
                                    <Card.Body >
                                        <div className="chartjs-wrapper-demo myChartSaah">
                                            <DonutApexChart data={[getVisitByUsersData.data.smartGuide, getVisitByUsersData.data.easyGuide]} type="big" width={300} height={287} />
                                        </div>
                                        <div className='mt-2 px-5 pt-2 pt-5'>
                                            <Row className='productivity-row-table title'>
                                                <Col sm="4" className='table-align-left p-0'>Herramientas</Col>
                                                <Col sm="4" className='text-center'>Usuarios</Col>
                                                <Col sm="4" className='table-align-right'>Porcentaje</Col>
                                            </Row> 
                                            <hr className='hr-productivity'/>
                                            <Row className='productivity-row-table'>
                                                <Col sm="4"  className='table-align-left'>Easy Guide</Col>
                                                <Col sm="4"  className='productivity-table-number'>{getVisitByUsersData.data.easyGuide.toLocaleString("de", {minimumFractionDigits: 0, maximumFractionDigits: 2})}</Col>
                                                <Col sm="4"  className='table-align-right'>{(getVisitByUsersData.data.easyGuide * 100 / (getVisitByUsersData.data.all || 1)).toFixed(0)}%</Col>
                                            </Row>
                                            <Row className='productivity-row-table'>
                                                <Col sm="4"  className='table-align-left'>Smart Guide</Col> 
                                                <Col sm="4"  className='productivity-table-number'>{getVisitByUsersData.data.smartGuide.toLocaleString("de", {minimumFractionDigits: 0, maximumFractionDigits: 2})}</Col>
                                                <Col sm="4"  className='table-align-right'>{(getVisitByUsersData.data.smartGuide * 100 / (getVisitByUsersData.data.all || 1)).toFixed(0)}%</Col>
                                            </Row>
                                            <Row className='productivity-row-table'>
                                                <Col sm="4"  className='table-align-left'>Total</Col>
                                                <Col sm="4"  className='productivity-table-number'>{getVisitByUsersData.data.all.toLocaleString("de", {minimumFractionDigits: 0, maximumFractionDigits: 2})}</Col>
                                                <Col sm="4"  className='table-align-right'>{((getVisitByUsersData.data.easyGuide * 100 / (getVisitByUsersData.data.all || 1)) + (getVisitByUsersData.data.smartGuide * 100 / (getVisitByUsersData.data.all || 1))).toFixed(0)}%</Col>
                                            </Row>
                                        </div>
                                    </Card.Body>
                                </div>
                            </>}
                    </Card>
                </Col>

                <Col sm={12} md={12} lg={12} xl={6}>
                    <Card>
                        {getVisitByHour.loading ?
                            <DnaLoader /> :
                            <>
                                <div>
                                    <Card.Header>
                                        <Card.Title as="h3">Horas con más Visitas</Card.Title>
                                    </Card.Header>
                                    <Card.Body >
                                        <div className="chartjs-wrapper-demo myChartSaah">
                                            <BarChart data={getVisitByHour.data} />
                                        </div>

                                    </Card.Body>
                                </div>
                            </>}
                    </Card>
                </Col>

            </Row>
            {/* <!-- ROW-2 END --> */}
            {/* Section for PDF */}
            {getVisitByUsersData.loading ?
                null :
                <>
                    <Card.Body style={{ zIndex: '-999', position: 'fixed', top: 9999 }} >
                        <div id="graphic-productivity-1" className="chartjs-wrapper-demo myChartSaah">
                            <TotalUserPDF />
                        </div>
                    </Card.Body>
                </>}

            {getVisitByUsersData.loading ?
                null :
                <>
                    <Card.Body style={{ zIndex: '-999', position: 'fixed', top: 9999 }} >
                        <div id="graphic-productivity-3" className="chartjs-wrapper-demo myChartSaah">
                            <DonutApexChart data={[getVisitByUsersData.data.smartGuide, getVisitByUsersData.data.easyGuide]} type="smart" width={1920} height={1080} />
                        </div>
                    </Card.Body>
                </>}
            {getVisitByUsersData.loading ?
                null :
                <>
                    <Card.Body style={{ zIndex: '-999', position: 'fixed', top: 9999 }} >
                        <div id="graphic-productivity-2" className="chartjs-wrapper-demo myChartSaah">
                            <DonutApexChart data={[getVisitByUsersData.data.easyGuide, getVisitByUsersData.data.smartGuide]} type="easy" width={1920} height={1080} />
                        </div>
                    </Card.Body>
                </>}
            {getVisitByUsersData.loading ?
                null :
                <>
                    <Card.Body style={{ zIndex: '-999', position: 'fixed', top: 9999 }} >
                        <div id="graphic-productivity-4" className="chartjs-wrapper-demo myChartSaah">
                            <DonutApexChart data={[getVisitByUsersData.data.smartGuide, getVisitByUsersData.data.easyGuide]} type="big" width={1920} height={1080} />
                        </div>
                    </Card.Body>
                </>}
            {getVisitByHour.loading ?
                null :
                <>
                    <Card.Body style={{ zIndex: '-999', position: 'fixed', top: 9999 }}>
                        <div id="graphic-productivity-5" className="chartjs-wrapper-demo myChartSaah">
                            <BarChartPDF data={getVisitByHour.data} />
                        </div>
                    </Card.Body>
                </>}
        </div>
    )
}

export default ProductivityUX;
