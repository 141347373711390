import { Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js'

const options: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      bottom: 20,
    }
  },
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
    datalabels: {
      anchor: "end",
      align: "start",
      display: true,
      color: "#858585",
      formatter: Math.round,
      offset: -20,
    },
    tooltip: {
      enabled: false
    }
  },
};

export const BarChart = ({ data }) => {
  return <Bar className='h-450' options={options} data={data} />;
}
