import React from 'react';
import styles from './scss/Dashboard.module.scss';
import PageHeader from '../../../Layouts/PageHeader/PageHeader';
import { Card, Col, Row } from 'react-bootstrap';
import { TComponentUX } from './types/TServicesExploitation'
import { FilterSelector } from "components/filters/Filter";
import { ResponsiveDataTable } from 'Data/Pages/TablesData/TableData';
import { DnaLoader } from 'components/loaders/DnaLoader';

const InterestsGeneralUX: React.FC<TComponentUX> = ({ getArtworkMostConsulted, downloadPDF }) => {

  return (
    <div className={styles.Dashboard}>
      <PageHeader titles="Interacción con el Contenido / Intereses Generales" active="Interacción Contenidos" items={['Home']} downloadPDF={downloadPDF} />
      {/* <!-- ROW-1 --> */}
      <FilterSelector /> <br />
      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          <Row>
            <Col lg={12} md={12} sm={12} xl={12}>
              {getArtworkMostConsulted.loading? <DnaLoader /> : <Card.Body id="graphic-interestGeneral-1">
                <div className="table-responsive" >
                  <ResponsiveDataTable data={getArtworkMostConsulted.data} />
                </div>
              </Card.Body>}
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <!-- ROW-1 END --> */}

    </div>
  )
}

export default InterestsGeneralUX;
