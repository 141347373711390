import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { actionCreators } from "store";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const handleGeneratePdfAllPage = async (
  dispatch: Dispatch<AnyAction>
) => {
  const { updateIsLoadingPdf } = bindActionCreators(actionCreators, dispatch);
  try {
    var item: any = document.getElementById("body");
    var hasClase2 = item.classList.contains("dark-mode");
    const bg = hasClase2 ? "#2A2A4A" : "#EFEFF4";

    updateIsLoadingPdf(true);
    const data1: any = document.getElementById("graphic-spaceInteraction-1");
    const data2: any = document.getElementById("graphic-spaceInteraction-2");
    const data3: any = document.getElementById("graphic-spaceInteraction-3");
    const data4: any = document.getElementById("graphic-spaceInteraction-4");

    const imgWidth1 = 207;

    let canvas1 = await html2canvas(data1, {
      backgroundColor: bg,
      allowTaint: true,
      useCORS: true,
    });
    let canvas2 = await html2canvas(data2, {
      backgroundColor: bg,
      allowTaint: true,
      useCORS: true,
    });
    let canvas3 = await html2canvas(data3, {
      backgroundColor: bg,
      allowTaint: true,
      useCORS: true,
    });
    let canvas4 = await html2canvas(data4, {
      backgroundColor: bg,
      allowTaint: true,
      useCORS: true,
    });

    const imgHeight1 = (canvas4.height * imgWidth1) / canvas4.width;

    let doc = new jsPDF("p", "mm", "letter");

    const mm1 = canvas1.toDataURL("image/png");
    const mm2 = canvas2.toDataURL("image/png");
    const mm3 = canvas3.toDataURL("image/png");
    const mm4 = canvas4.toDataURL("image/png");

    doc.text("Interacción con el Espacio", 80, 15);

    doc.addImage(mm1, "PNG", 4, 30, 65, 25);
    doc.addImage(mm2, "PNG", 74, 30, 65, 25);
    doc.addImage(mm3, "PNG", 144, 30, 65, 25);
    doc.text("Detalle Consulta por planta", 7, 70);
    doc.addImage(mm4, "PNG", 4, 60, imgWidth1, imgHeight1);

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    doc.save(`Productividad_${mm + "/" + dd + "/" + yyyy}.pdf`);
  } catch (error) {
    console.log("Error Download PDF", error);
  } finally {
    updateIsLoadingPdf(false);
  }
};
