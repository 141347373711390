import { ActionType } from "../action-types";
import { Action } from "../actions/index";
import { defaultDates } from "utils/defaultDates";
import dayjs from "dayjs";

const initialState = {
  startDate: dayjs(defaultDates.startDate).format("YYYY-MM-DD HH:mm:ss"),
  endDate: dayjs(defaultDates.endDate).format("YYYY-MM-DD HH:mm:ss"),
  tools: "0",
  museum: null,
  languageCode: "Todos",
  route: null,
};

const reducer = (state: any = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.UPDATE_FILTER:
      state = action.payload;
      return state;
    case ActionType.UPDATE_FILTER_MUSEUM:
      return {
        ...state,
        museum: action.payload,
      };
    case ActionType.UPDATE_FILTER_INITIAL:
      return {
        ...state,
        museum: action.payload.museum,
        route: null,
        languageCode: "Todos",
        tools: '0'
      };
    default:
      return state;
  }
};

export default reducer;
