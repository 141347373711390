
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

export const DonutApexChart: React.FC<any> = ({ data, type, width, height, id }) => {

  let dataChart;
  let optionsChart;

  const optionForPie = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1500,
      delay: 200
    },
    layout: {
      padding: {
        display: false,
        bottom: 0,
        color: 'white'
      }
    },
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
        labels: {
          color: 'white'
        }
      },
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
      },
      datalabels: {
        display: false,
      },
    },

  };

  const optionForPie2 = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1500,
      delay: 200
    },
    layout: {
      padding: {
        display: false,
        bottom: 0,
        color: 'white'
      }
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom' as const,
        labels: {
          color: 'grey'
        }
      },
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
      },
      datalabels: {
        anchor: "center",
        align: "center",
        display: true,
        color: "white",
        font: {
          size: 16,
        },
        formatter: function (value, context) {
          const total = context.dataset.data.reduce((a, partialSum) => a + partialSum, 0);
          return Math.round((Math.round(value) * 100) / total) + '%';
        }
      },
    },

  };

  const data4 = {
    labels: ['EasyGuide', 'SmartGuide'],
    datasets: [
      {
        label: '# of Votes',
        data,
        borderWidth: 0,
        backgroundColor: [
          '#5A43C0',
          '#8B7BD2',
          '#BDB3E5',
          '#9340FF',
          '#7376F1',
        ],

      },
    ],
  };

  const data5 = {
    labels: ['SmartGuide', 'EasyGuide'],
    datasets: [
      {
        label: '# of Votes',
        data,
        borderWidth: 0,
        backgroundColor: [
          '#5A43C0',
          '#8B7BD2',
          '#BDB3E5',
          '#9340FF',
          '#7376F1',
        ],

      },
    ],
  };
  const data6 = {
    labels: ['SmartGuide', 'EasyGuide'],
    datasets: [
      {
        label: '# of Votes',
        data,
        borderWidth: 0,
        backgroundColor: [
          '#839192',
          '#707b7c',
          '#BDB3E5',
          '#9340FF',
          '#7376F1',
        ],

      },
    ],
  };

  if (type === 'easy') {
    dataChart = data4
    optionsChart = optionForPie
  } else if (type === 'big') {
    dataChart = data6
    optionsChart = optionForPie2
  } else {
    dataChart = data5
    optionsChart = optionForPie
  }

  return (
    <>
      <Doughnut id={id} data={dataChart} options={optionsChart} width={width} height={height} />
    </>
  )
}
