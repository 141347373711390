export const parseDataTotalHours = (dataIn: any) => {
  const labels = dataIn.map((label: any) => label.hour);
  const datas = dataIn.map((e: any) => e.visits);
  const datasets = [
    {
      label: "Horas",
      data: datas,
      backgroundColor: ['#6c5ffc'],
      borderColor: ['#BDB3E5'],
    }
  ];

  const dataOut = {
    labels,
    datasets
  };

  return dataOut;
};
