import { ResponsiveDataTable } from "Data/Pages/TablesData/TableData";
import { FC } from "react"
import { Card } from 'react-bootstrap';
import { ITableOption } from "../types";

export const TableOption: FC<ITableOption> = ({ props }) => {
    const {data, title} = props;
    return (
        <div className="card overflow-hidden">
            <Card.Body id="graphic-spaceInteraction-4">
                <Card.Title className='m-2' as="h3">{title}</Card.Title>
                <div className="table-responsive mt-4">
                    <ResponsiveDataTable data={data} />
                </div>
            </Card.Body>
        </div>
    )
}