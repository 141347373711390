import { combineReducers } from 'redux';
import userReducer from './userReducer';
import isAuthenticatedReducer from './isAuthenticatedReducer';
import isLoadingReducer from './isLoadingReducer';
import filterReducer from './filterReducer';
import pdfReducer from './isLoadingPdfReducer';
import museumReducer from './museumReducer';
import languagesReducer from './languagesReducer';
import roleReducer from './roleReducer';
import routesReducer from './routesReducer';
import { persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storageIDB from 'redux-persist-indexeddb-storage';

const persistConfig = {
  key: 'visitorAnalytics',
  storage: storageIDB('mm_va_2'),
  blacklist: ['museumList'],
  transforms: [
    encryptTransform({
      secretKey: '@visitor-analytics-key-museummate!',
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
};

const reducers = combineReducers({
  filters: filterReducer,
  isAuthenticated: isAuthenticatedReducer,
  isLoading: isLoadingReducer,
  user: userReducer,
  isLoadingPdf: pdfReducer,
  museumList: museumReducer,
  languages: languagesReducer,
  role: roleReducer,
  routes: routesReducer,
});

export default persistReducer(persistConfig, reducers);

export type State = ReturnType<typeof reducers>;
