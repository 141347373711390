import { dataXMLByDevice } from "../types";

export const parserDataByDevice = (body: dataXMLByDevice) => {

    return {
        headers: [
            {
                Header: "Mes",
                accessor: "month",
                className: "text-center wd-15p border-bottom-0 ",
                toolTip: "", 
            },
            {
                Header: "Ruta",
                accessor: "route",
                className: "text-left wd-25p border-bottom-0 ",
                toolTip: ""
            },
            {
                Header: "N. visitas",
                accessor: "visits",
                className: "text-left wd-15p border-bottom-0 ",
                toolTip: "",
                Cell: row => (<div className="tooltip-table" data-toggle="tooltip" data-placement="top" title={row.value} style={{ textAlign: "center" }}>{row.value}</div>),
            },
            {
                Header: "Dispositivo",
                accessor: "device",
                className: "text-right wd-15p border-bottom-0 ",
                toolTip: ""
            },
        ],
        body
    }
}