import { getTotalHours } from "services/api"
import { TFetchRequest } from "pages/productivity/types/TServicesExploitation"
import { TRequestResponseHour } from "../types/TServicesExploitation"

export const RequestFetchVisitByHour = (): TFetchRequest =>  {
    const defaultVisitByUser: TRequestResponseHour = {
        labels: [''],
        datasets: [
            {
                label: '',
                data: [''],
                backgroundColor: '#BDB3E5',
            },

        ],
    };

    return {request: getTotalHours, defaultData: defaultVisitByUser}
}