import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { actionCreators } from 'store';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';

export const handleGeneratePdfAllPage = async (dispatch: Dispatch<AnyAction>) => {
    const {
        updateIsLoadingPdf
    } = bindActionCreators(actionCreators, dispatch);
    try {
        updateIsLoadingPdf(true);
        const data1: any = document.getElementById("graphic-habitsGeneral-1");
        const data2: any = document.getElementById("graphic-habitsGeneral-2");
        const data3: any = document.getElementById("graphic-habitsGeneral-3");
        const data4: any = document.getElementById("graphic-habitsGeneral-4");
        const data5: any = document.getElementById("graphic-habitsGeneral-5");
        const data6: any = document.getElementById("graphic-habitsGeneral-6");
        
    
        const imgWidthArray = 65;
        const imgHeightArray = 25;
    
        let canvas1 = await html2canvas(data1, { backgroundColor: '#2A2A4A' });
        let canvas2 = await html2canvas(data2, { backgroundColor: '#2A2A4A' });
        let canvas3 = await html2canvas(data3, { backgroundColor: '#2A2A4A' });
        let canvas4 = await html2canvas(data4, { backgroundColor: '#2A2A4A' });
        let canvas5 = await html2canvas(data5, { backgroundColor: '#2A2A4A' });
        let canvas6 = await html2canvas(data6, { backgroundColor: '#2A2A4A' });
    
    
        let doc = new jsPDF('p', 'mm', 'letter');
    
        const mm1 = canvas1.toDataURL('image/png');
        const mm2 = canvas2.toDataURL('image/png');
        const mm3 = canvas3.toDataURL('image/png');
        const mm4 = canvas4.toDataURL('image/png');
        const mm5 = canvas5.toDataURL('image/png');
        const mm6 = canvas6.toDataURL('image/png');
    
        doc.text('Hábitos Generales', 80, 15);
    
        doc.addImage(mm1, 'PNG', 4, 30, imgWidthArray, imgHeightArray)
        doc.addImage(mm2, 'PNG', 74, 30, imgWidthArray, imgHeightArray)
        doc.addImage(mm3, 'PNG', 144, 30, imgWidthArray, imgHeightArray)
        doc.addImage(mm4, 'PNG', 4, 60, imgWidthArray, imgHeightArray)
        doc.addImage(mm5, 'PNG', 74, 60, imgWidthArray, imgHeightArray)
        doc.addImage(mm6, 'PNG', 144, 60, imgWidthArray, imgHeightArray)
    
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
    
        doc.save(`Productividad_${mm + '/' + dd + '/' + yyyy}.pdf`);
    } catch (error) {
        console.log('Error Download PDF', error);
    } finally {
        updateIsLoadingPdf(false);
    }
};