import { Dispatch } from 'redux';
import { ActionType } from '../action-types/index';
import { Action } from '../actions/index';

export const updateUser = (user: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_USER,
      payload: user,
    });
  };
};


export const updateIsAuthenticated = (isAuthenticated: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_IS_AUTHENTICATED,
      payload: isAuthenticated,
    });
  };
};

export const updateIsLoading = (isLoading: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_IS_LOADING,
      payload: isLoading,
    });
  };
};

export const updateIsLoadingPdf = (isLoading: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_IS_LOADING_PDF,
      payload: isLoading,
    });
  };
};

export const updateMuseum = (museum: object) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_MUSEUM,
      payload: museum,
    });
  };
};

export const updateLanguages = (ArrayLanguages: Array<object>) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_LANGUAGES,
      payload: ArrayLanguages,
    });
  };
};

export const updateFilterMuseum = (museum: number) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_FILTER_MUSEUM,
      payload: museum,
    });
  };
};

export const updateFilterInitial = (payload: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_FILTER_INITIAL,
      payload,
    });
  };
};

export const updateRole = (role: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_ROLE,
      payload: role,
    });
  };
};

export const updateRoutes = (routes: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_ROUTES,
      payload: routes,
    });
  };
};