import dayjs from "dayjs";
import { defaultDates } from "utils/defaultDates";

export const defaultFilters = (filters, museumList) => {
  let data;
  if (filters.length === 0) {
    data = {
      startDate: dayjs(defaultDates.startDate).toISOString().split("T")[0],
      endDate: dayjs(defaultDates.endDate).toISOString().split("T")[0],
      startTime: dayjs("2014-08-18T08:00:00").hour(),
      endTime: dayjs("2014-08-18T22:00:00").hour(),
      tools: "0",
      museum: museumList[0].id,
      languageCode: "Todos",
      route: null
    };
    return data;
  }

  if(filters.hasOwnProperty('floor') || filters.hasOwnProperty('idHall')){
    data = {
      startDate: dayjs(filters.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(filters.endDate).format('YYYY-MM-DD'),
      startTime: dayjs(filters.startDate).hour(),
      endTime: dayjs(filters.endDate).hour(),
      tools: filters.tools,
      museum: filters.museum,
      floor: filters.floor,
      idHall: filters.idHall,
      languageCode: filters.languageCode,
      route: filters.route || null
    };
    return data;
  }

  data = {
    startDate: dayjs(filters.startDate).format('YYYY-MM-DD'),
    endDate: dayjs(filters.endDate).format('YYYY-MM-DD'),
    startTime: dayjs(filters.startDate).hour(),
    endTime: dayjs(filters.endDate).hour(),
    tools: filters.tools,
    museum: filters.museum,
    languageCode: filters.languageCode,
    route: filters.route || null
  };

  return data;
};

export default defaultFilters;
