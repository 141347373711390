import { FC } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { DayCounter } from '../../../../Data/Pages/Extension/DataUnderConstruction';
import styles from './UnderConstruction.module.scss';


interface UnderConstructionProps { }

const UnderConstruction: FC<UnderConstructionProps> = () => (
    <div className={styles.Dashboard}>
        <Row>
            <Col lg={12} md={12} sm={12} xl={12}>
                <Row>
                    <Col lg={12} md={12} sm={12} xl={12}>
                        <div className={styles.UnderConstruction}>
                            <div className='login-img'>
                                <div className="page">
                                    <div className='container'>
                                        <Row className="text-center mx-auto">
                                            <Col lg={11} sm={12} className="center-block construction">
                                                <div className="text-white">
                                                    <Card.Body className="p-0">
                                                        <h1 className="display-2 mb-0 fw-semibold mb-5">Coming Soon</h1>
                                                        <div >
                                                            <DayCounter />
                                                        </div>
                                                        <p className='mt-5'>Nos disculpamos por su inconveniencia... Cualquier duda contactar conmigo </p>
                                                        <h4 className='mt-5'><strong>Contacto:</strong> ejemplo@museummate.com</h4>
                                                        <div className="mt-5">
                                                            <Button className="btn btn-icon" type="button">
                                                                <span className="btn-inner--icon"><i className="fa fa-facebook-f"></i></span>
                                                            </Button>
                                                            <Button className="btn btn-icon" type="button">
                                                                <span className="btn-inner--icon"><i className="fa fa-google"></i></span>
                                                            </Button>
                                                            <Button className="btn btn-icon" type="button">
                                                                <span className="btn-inner--icon"><i className="fa fa-twitter"></i></span>
                                                            </Button>
                                                            <Button className="btn btn-icon" type="button">
                                                                <span className="btn-inner--icon"><i className="fa fa-pinterest-p"></i></span>
                                                            </Button>
                                                        </div>
                                                    </Card.Body>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
        {/* <!-- ROW-1 END --> */}

    </div>

);

export default UnderConstruction;
