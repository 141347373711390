import PageHeader from "Layouts/PageHeader/PageHeader"
import { FilterSelector } from "components/filters/Filter"
import { FC, useEffect, useState } from "react"
import { TOptions } from "./types";
import { SelectorOption } from "./components/SelectorOption";
import { TableOption } from "./components/TableOption";
import { downloadByDevice } from "./handlers/downloadByDevice";
import { DnaLoader } from "components/loaders/DnaLoader";
import { useSelector } from "react-redux";
import { State } from "store/reducers";
import { parserDataByDevice } from "./helpers/parserDataByDevice";
import { parserDataByLanguage } from "./helpers/parserDataByLanguage";
import { downloadByLanguage } from "./handlers/downloadByLanguage";
import { getToursByRouteLanguage } from "services/api/getToursByRouteLanguage";
import { getToursByRouteDevice } from "services/api/getToursByRouteDevice";

export const ExportData: FC<{}> = () => {
    const [options, setOptions] = useState<TOptions>('device')
    const [loading, setLoading] = useState<boolean>(true);
    const [rawData, setRawData] = useState<any>(null);
    const [dataTable, setDataTable] = useState<{ title: string, data: any }>({ title: '', data: '' });
    const filters = useSelector((state: State) => state.filters);
    const museumList = useSelector((state: State) => state.museumList);

    const handlerOption = (data: TOptions) => {
        setOptions(data)
    }

    const handleDownload = () => {
        try {
            if (!rawData) {
                return;
            }
            const {museum, startDate, endDate} = filters || {};
            const nameMuseum = museumList.find(e => e.id === museum)?.label || 'NotFound';
            setLoading(true);
            if (options === 'device') {
                downloadByDevice(rawData, nameMuseum, startDate, endDate)
            } else {
                downloadByLanguage(rawData, nameMuseum, startDate, endDate)
            }
        } catch (error) {
            console.error({ error })
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);
                let result
                if (options === 'device') {
                    result = await getToursByRouteDevice(filters);
                    setDataTable({ title: 'Dispositivos', data: parserDataByDevice(result) })
                } else {
                    result = await getToursByRouteLanguage(filters);
                    setDataTable({ title: 'Lenguajes', data: parserDataByLanguage(result) })
                }
                setRawData(result)
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false);
            }
        }
        getData()
    }, [options, filters])

    return (
        <div>
            <PageHeader titles="Visitas por Recorridos" active="Interacción Contenidos" XMLHandler={handleDownload} disablePDF />
            <FilterSelector hideTools hideLanguage />
            <SelectorOption handler={handlerOption} optionSelected={options} />
            {loading ?
                <DnaLoader />
                :
                <TableOption props={dataTable} />
            }
        </div >
    )
}