import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { actionCreators } from "store";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'

export const handleGeneratePdfAllPage = async (
  dispatch: Dispatch<AnyAction>, data: any, 
) => {
  console.log('datttt', data)
  const { updateIsLoadingPdf } = bindActionCreators(actionCreators, dispatch);
  try {
    updateIsLoadingPdf(true);
    //const data1: any = document.getElementById("graphic-productivity-1");
    const data2: any = document.getElementById("graphic-productivity-2");
    const data3: any = document.getElementById("graphic-productivity-3");
    const data4: any = document.getElementById("graphic-productivity-4");
    const data5: any = document.getElementById("graphic-productivity-5");

    // let canvas1 = await html2canvas(data1);
    let canvas2 = await html2canvas(data2);
    let canvas3 = await html2canvas(data3);
    let canvas4 = await html2canvas(data4);
    let canvas5 = await html2canvas(data5);

    let doc = new jsPDF("p", "mm", "letter");

    // const mm1 = canvas1.toDataURL("image/png");
    const mm2 = canvas2.toDataURL("image/png");
    const mm3 = canvas3.toDataURL("image/png");
    const mm4 = canvas4.toDataURL("image/png");
    const mm5 = canvas5.toDataURL("image/png");

    doc.setFontSize(22);
    doc.text("Productividad", 90, 15);
    doc.setFontSize(14);
    doc.text("Usuarios Smart Guide", 34, 25);
    doc.addImage(mm2, "PNG", 18, 32, 80, 40);
    doc.text(data.data1.smartGuide.toString(), 34, 25);
    doc.text("Usuarios EasyGuide", 144, 25);
    doc.addImage(mm3, "PNG", 128, 32, 80, 40);
    doc.text("Usuarios por Herramientas", 10, 85);
    doc.addImage(mm4, "PNG", 55, 90, 110, 55);
    autoTable(doc, {
      head: [['Herramienta', 'Usuarios', 'Porcentaje']],
      body: [
        ['Smart Guide', data.data1.smartGuide.toString(), `${(data.data1.smartGuide * 100 / data.data1.all).toFixed(0).toString()}%`],
        ['Easy Guide', data.data1.easyGuide.toString(), `${(data.data1.easyGuide * 100 / data.data1.all).toFixed(0).toString()}%`],
        ['Todos', data.data1.all.toString(), `100%`],
      ],
      startY: 160,
    })
    doc.addPage()
    doc.text("Horas con más Visitas", 10, 10);
    doc.addImage(mm5, "PNG", 10, 15, 190, 75);


    // doc.text("Usuarios Totales", 14, 25);
    // doc.addImage(mm4, "PNG", 4, 35, 130, 65);
    // doc.addImage(mm1, "PNG", 4, 30, imgWidthArray, imgHeightArray);
    // doc.addImage(mm2, "PNG", 74, 30, imgWidthArray, imgHeightArray);
    // doc.addImage(mm3, "PNG", 144, 30, imgWidthArray, imgHeightArray);
    // doc.addImage(mm4, "PNG", 4, 60, 100, 60);
    // doc.addImage(mm5, "PNG", 108, 60, 100, 60);

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    doc.save(`Productividad_${mm + "/" + dd + "/" + yyyy}.pdf`);
  } catch (error) {
    console.log("Error Download PDF", error);
  } finally {
    updateIsLoadingPdf(false);
  }
};
