/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import Col from 'react-bootstrap/Col';
import { defaultDates } from 'utils/defaultDates'
import { Card, Row } from "react-bootstrap";
import { ActionType, State } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { getMuseum, getLanguagesByMuseum, getRoutesByMuseum } from 'services/api'
import React from "react";
import dayjs from 'dayjs';
import { DateTimePicker } from 'react-rainbow-components';

interface IFilters {
  museum: Array<any>,
  tools: string | number,
  startDate: any,
  endDate: any
  languageCode: string,
  route: string 
}

interface IFilterSelector {
  hideTools?: boolean,
  hideLanguage?: boolean
}

export const FilterSelector: React.FC<IFilterSelector> = ({hideLanguage, hideTools}) => {

  const { museum, tools, startDate: startDateRedux, endDate: endDateRedux, languageCode: languageSelected }: IFilters = useSelector((state: State) => state.filters);
  const languages: Array<any> = useSelector((state: State) => state.languages);
  const [startDate, setStartDate] = useState<Date>(new Date(startDateRedux || dayjs(defaultDates.startDate)));
  const [endDate, setEndDate] = useState<Date>(new Date(endDateRedux || defaultDates.endDate));
  const dispatch = useDispatch();
  const museumList: any = useSelector((state: State) => state.museumList);
  const routes: any = useSelector((state: State) => state.routes);

  const getDatatoSend = useCallback(() => {
    let tools: any = document.getElementById("tools") || null;
    let language: any = document.getElementById("language") || null;
    let routeSelected: any = document?.getElementById("routes") || null;
    let dataToSend = {
      'startDate': dayjs(startDate).format('YYYY-MM-DD HH:mm:ss'),
      'endDate': dayjs(endDate).format('YYYY-MM-DD HH:mm:ss'),
      tools: tools?.value || null,
      languageCode: language?.value || null,
      museum: museum || museumList[0].id,
      route: routeSelected?.value === 'null' ? null : routeSelected?.value
    };

    dispatch({
      type: ActionType.UPDATE_FILTER,
      payload: dataToSend,
    });
  }, [dispatch, endDate, museum, startDate])


  const getLanguagesByMuseumCallback = useCallback(async () => {
    if (languages?.length === 0) {
      const response = await getLanguagesByMuseum(museum);
      dispatch({
        type: ActionType.UPDATE_LANGUAGES,
        payload: response,
      });
    }

  }, [dispatch, languages, museum])

  const getRoutesByMuseumCallback = useCallback(async () => {
    if (!routes) {
      const response = await getRoutesByMuseum(museum);
      dispatch({
        type: ActionType.UPDATE_ROUTES,
        payload: response,
      });
    }
  }, [dispatch, museum, routes])


  const getMuseumCallback = useCallback(async () => {
    if (museumList === null || museumList.length === undefined || museumList.length === 0) {
      const response = await getMuseum();
      dispatch({
        type: ActionType.UPDATE_MUSEUM,
        payload: response,
      });
    }
  }, [dispatch, museumList])

  useEffect(() => {
    getMuseumCallback();
    getLanguagesByMuseumCallback();
    getRoutesByMuseumCallback();
  }, [getLanguagesByMuseumCallback, getMuseumCallback, getRoutesByMuseumCallback, languages])

  return (
    <>
      <Card className="card d-flex overflow-hidden pb-3 mb-1">
        <Card.Header>
          <Card.Title as="h3">Filtros</Card.Title>
        </Card.Header>
        <Card.Body >
          <Row>
            <Col xl="6" lg="12" xxl={hideLanguage && hideTools ? '5' : '3'} md="12" sm="12">
              <div style={{ textAlign: 'center' }}>
                <label className='colorinput'>Fecha inicial</label>
                <DateTimePicker
                  id="dateRainbow"
                  value={startDate}
                  onChange={e => setStartDate(e)}
                  formatStyle="large"
                  locale={'es-ES'}
                  okLabel={'Aceptar'}
                  cancelLabel={'Cancelar'}
                  borderRadius="semi-square"
                  size={'small'}
                  valueAlignment={"left"}
                  hour24
                  className="filter-rainbowCalendar"
                />
              </div>
            </Col>
            <Col xl="6" lg="12" xxl={hideLanguage && hideTools ? '5' : '3'} md="12" sm="12">
              <div style={{ textAlign: 'center' }}>
                <label className='colorinput'>Fecha Final</label>
                <DateTimePicker
                  id="dateRainbow"
                  value={endDate}
                  onChange={e => setEndDate(e)}
                  formatStyle="large"
                  locale={'es-ES'}
                  okLabel={'Aceptar'}
                  cancelLabel={'Cancelar'}
                  borderRadius="semi-square"
                  size={'small'}
                  valueAlignment={"left"}
                  hour24
                  className="filter-rainbowCalendar"
                />
              </div>
            </Col>
            <Col hidden={hideTools} xl="6" lg="6" xxl="2" md="6" sm="12">
              <div style={{ textAlign: 'center' }}>
                <label className='colorinput' htmlFor="herramienta">Herramientas</label>
                <select defaultValue={tools || "0"} id="tools" className="form-select dropdown-filter" aria-label="Select example">
                  <option selected={tools === "0"} value="0">Todas</option>
                  <option value="1">Smart Guide</option>
                  <option value="2">Easy Guide</option>
                </select>
              </div>
            </Col>

            {/* {routes.length > 0 && <Col xl="6" lg="6" xxl="2" md="6" sm="12">
              <div style={{ textAlign: 'center' }}>
                <label className='colorinput' htmlFor="herramienta">Recorridos</label>
                <select defaultValue={routePreselect? 'null': routePreselect} id="routes" className="form-select dropdown-filter" aria-label="Select example">
                  <option selected={routePreselect === null} value="null">Todas</option>
                  {routes.map((e: any, index) => {
                    return (<option key={'route' + index} value={e.id}>{e.name}</option>)
                  })}
                </select>
              </div>
            </Col>} */}

            <Col hidden={hideLanguage} xl="6" lg="6" xxl="2" md="6" sm="12">
              <div style={{ textAlign: 'center' }}>
                <label className='colorinput' htmlFor="herramienta">Idioma</label>
                <select defaultValue={languageSelected || "Todos"} id="language" className="form-select dropdown-filter" aria-label="Select example">
                  <option selected={languageSelected === "Todos"} value="Todos">Todos</option>
                  {languages.map((e: any, index) => {
                    return (<option key={'language' + index} value={e.code}>{e.label}</option>)
                  })}
                </select>
              </div>
            </Col>

            <Col xl="12" lg="12" xxl="1" md="12" sm="12">
              <div style={{ textAlign: 'center' }}>
                <label className='colorinput' htmlFor="herramienta">Buscar</label>
                <button onClick={() => getDatatoSend()} style={{ paddingLeft: '50%', paddingRight: '50%' }} className="btn input-group-text">
                  <i className="fa fa-search fs-16" aria-hidden="true"></i>
                </button>
              </div>
            </Col>

          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
