import { getAvgNumWorkConsumedVisitor } from "services/api"
import { TFetchRequest } from "pages/productivity/types/TServicesExploitation"
import { TRequestAvgNumWorkConsumedVisitor } from "../types/TServicesExploitation"

export const RequestAvgNumWorkConsumedVisitor = (): TFetchRequest =>  {
    const defaultData: TRequestAvgNumWorkConsumedVisitor = {
        averageNumber: 0,
    };

    return {request: getAvgNumWorkConsumedVisitor, defaultData}
}