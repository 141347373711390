type TUsersAuth = Array<{
  idMuseum: null | number;
  role: string;
  user: string;
  pass: string;
}>;

export const UsersAuth: TUsersAuth = [
  {
    idMuseum: null,
    role: "ADMIN",
    user: "gestor@museummate.net",
    pass: "mm_va_231",
  },
  {
    idMuseum: null,
    role: "ADMIN",
    user: "alopez@museummate.com",
    pass: "va_mm_al_23",
  },
  {
    idMuseum: null,
    role: "ADMIN",
    user: "contents@museummate.com",
    pass: "e_content_04",
  },
  {
    idMuseum: null,
    role: "ADMIN",
    user: "ocarrascoso@museummate.com",
    pass: "ocarr_23_va",
  },
  {
    idMuseum: 4,
    role: "MUSEUM",
    user: "edtorino@museummate.net",
    pass: "torino_2023",
  },
  {
    idMuseum: 52,
    role: "MUSEUM",
    user: "pguel@museummate.net",
    pass: "palauguel_2023",
  },
  {
    idMuseum: 51,
    role: "MUSEUM",
    user: "vanrohe@museummate.net",
    pass: "mies_@van_Der-roe",
  },
  {
    idMuseum: 78,
    role: "MUSEUM",
    user: "cytdostorres@museummate.net",
    pass: "cyt2022DT",
  },
  {
    idMuseum: 43,
    role: "MUSEUM",
    user: "capricho@museummate.net",
    pass: "cat2024Dg",
  },
  {
    idMuseum: 94,
    role: "MUSEUM",
    user: "dnavas@mpicassom.org",
    pass: "va_dnavas",
  },
  {
    idMuseum: 94,
    role: "MUSEUM",
    user: "bvivas@mpicassom.org",
    pass: "va_bvivas",
  },
  {
    idMuseum: 94,
    role: "MUSEUM",
    user: "smgarcia@mpicassom.org",
    pass: "va_smgarcia",
  },
  {
    idMuseum: 94,
    role: "MUSEUM",
    user: "rmerino@mpicassom.org",
    pass: "va_rmerino",
  },
];
