import useFetch from "hooks/useFetch";
import { RequestFetchVisitByUser } from "./helpers/RequestFetchVisitByUser"
import { RequestFetchVisitByHour } from "./helpers/RequestFetchVisitByHour"
import ProductivityUX from "./ProductivityUX"
import { useDispatch, useSelector } from 'react-redux';
import { State } from "store/reducers";
import { useState } from "react";
import { handleGeneratePdfAllPage } from "./helpers/ProductivityAllPdf";

export const Productivity: React.FC = () => {

    const dispatch = useDispatch();
    const filters = useSelector((state: State) => state.filters);
    const [downloadPDF] = useState<any>((data) => (data) => handleGeneratePdfAllPage(dispatch, data));

    const getVisitByUsersData = useFetch(RequestFetchVisitByUser(), filters);
    const getVisitByHour = useFetch(RequestFetchVisitByHour(), filters);

    return (
        <>
            <ProductivityUX getVisitByUsersData={getVisitByUsersData} getVisitByHour={getVisitByHour} downloadPDF={downloadPDF} />
        </>
    )
}


export default Productivity;
