import axios from "../axios";

export const getToursByRouteLanguage = (params) => {

  const { startDate, endDate, museum } = params;

  return axios
    .get(`/tours_by_route_language`, {
      params: {
        startDate: (new Date(startDate)).toISOString().split('T')[0].replaceAll("-", ""),
        endDate: (new Date(endDate)).toISOString().split('T')[0].replaceAll("-", ""),
        museum
      }
    })
    .then(({ data }) => {
      return data['hydra:member'];
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
