import adapters from "../../adapters/adapterTableLanguageVisits";
import axios from "../axios";

export const getLanguageVisits = ({params, cancelTokenSource}) => {

  return axios
    .get(`/most_consulted_works`, {
      cancelToken: cancelTokenSource,
      params
    })
    .then(({ data }) => {
      const dataParsed = adapters(data.mostConsultedWorks['hydra:member']);
      return dataParsed;
    } )
    .catch((error) => {
      return Promise.reject(error);
    });
};
