import axios from "axios";
export const source = axios.CancelToken.source();

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://localhost",

});

// axiosConfig.interceptors.request.use(async (config: any) => {
//   // config.headers['Authorization'] = `${token.replace(/['"]+/g, '')}`;
//   // config.headers["Authorization"] = `asd`;
//   config.headers["Content-Type"] = "application/json";  
//   return config;
// });

axiosConfig.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
    // store.dispatch({
    //   type: ActionType.UPDATE_IS_AUTHENTICATED,
    //   payload: false,
    // });
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});


export default axiosConfig;
