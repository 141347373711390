import React from 'react';
import styles from './scss/Dashboard.module.scss';
import PageHeader from '../../../Layouts/PageHeader/PageHeader';
import { Card, Col, Row } from 'react-bootstrap';
import { TotalUser } from '../../../Data/DataDashBoard/DashBoardData';
import { TComponentUX } from './types/TServicesExploitation'
import { DonutApexChart } from './components/DonutApexChart'
import { DnaLoader } from 'components/loaders/DnaLoader';
import { FilterSelector } from "components/filters/Filter";
import { ResponsiveDataTable } from 'Data/Pages/TablesData/TableData';

const HabitsGeneralUX: React.FC<TComponentUX> = ({ getAverageDurationVisit, getAvgNumWorkConsumedVisitor, getAvgTimeConsumedArtVisitor, downloadPDF, getLanguageVisits }) => {

    return (
        <div className={styles.Dashboard}>
            <PageHeader titles="Interacciòn con el Contenido / Hàbitos Generales" active="Interacciòn Contenidos" items={['Home']} downloadPDF={downloadPDF} />
            {/* <!-- ROW-1 --> */}
            <FilterSelector /> <br />
            <Row>
                <Col lg={12} md={12} sm={12} xl={12}>
                    <Row>
                        <Col lg={6} md={6} sm={12} xl={4}>
                            <Card className="card overflow-hidden">
                                {getAverageDurationVisit.loading ?
                                    <DnaLoader /> :
                                    <>
                                        <Card.Body id="graphic-habitsGeneral-1">
                                            <div className="d-flex" >
                                                <div className="mt-2">
                                                    <h6 className="">Tiempo Medio Total</h6>
                                                    <h2 className="mb-0 number-font">{getAverageDurationVisit.data.all.toLocaleString()} min</h2>
                                                </div>
                                                <div className="ms-auto">
                                                    <div className="chart-wrapper mt-1 p-1">
                                                        <TotalUser />
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </>}
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12} xl={4}>
                            <div className="card overflow-hidden">
                                {getAverageDurationVisit.loading ?
                                    <DnaLoader /> :
                                    <>
                                        <Card.Body id="graphic-habitsGeneral-2">
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className="">Tiempo Medio Smart Guide</h6>
                                                    <h2 className="mb-0 number-font">{getAverageDurationVisit.data.smartGuide.toLocaleString()} min</h2>
                                                </div>
                                                <div className="ms-auto">
                                                    <div className="chart-wrapper mt-1">
                                                        <DonutApexChart data={[getAverageDurationVisit.data.easyGuide, getAverageDurationVisit.data.smartGuide]} type="easy" width={110} height={80} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </>}
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xl={4}>
                            <div className="card overflow-hidden">
                                {getAverageDurationVisit.loading ? <DnaLoader /> :
                                    <Card.Body id="graphic-habitsGeneral-3">
                                        <div className="d-flex">
                                            <div className="mt-2">
                                                <h6 className="">Tiempo Medio Easy Guide</h6>
                                                <h2 className="mb-0 number-font">{getAverageDurationVisit.data.easyGuide.toLocaleString()} min</h2>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="chart-wrapper mt-1">
                                                    <DonutApexChart data={[getAverageDurationVisit.data.smartGuide, getAverageDurationVisit.data.easyGuide]} type="smart" width={110} height={80} />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={6} sm={12} xl={6}>
                            <div className="card overflow-hidden">
                                {getAvgNumWorkConsumedVisitor.loading ? <DnaLoader /> :
                                    <Card.Body id="graphic-habitsGeneral-4">
                                        <div className="d-flex">
                                            <div className="mt-2">
                                                <h6 className="">Número Medio de obras consumidas por cada visitante</h6>
                                                <h2 className="mb-0 number-font">{(+getAvgNumWorkConsumedVisitor.data.averageNumber).toFixed(0)}</h2>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="chart-wrapper mt-1 p-1">
                                                    <TotalUser />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>}
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xl={6}>
                            <div className="card overflow-hidden">
                                {getAvgTimeConsumedArtVisitor.loading ? <DnaLoader /> :
                                    <Card.Body id="graphic-habitsGeneral-5">
                                        <div className="d-flex">
                                            <div className="mt-2">
                                                <h6 className="">Tiempo medio consumido por obra y visitante del museo</h6>
                                                <h2 className="mb-0 number-font">{(+getAvgTimeConsumedArtVisitor.data.averageTimeConsumed).toFixed(0)} min</h2>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="chart-wrapper mt-1 p-1">
                                                    <TotalUser />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>}
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xl={12}>

                            <div className="card overflow-hidden">
                                {getLanguageVisits.loading ? <DnaLoader /> :
                                    <Card.Body id="graphic-spaceInteraction-4">
                                        <Card.Title className='m-2' as="h3">Consulta de obras más visitadas</Card.Title>
                                        <div className="table-responsive mt-4">
                                            <ResponsiveDataTable data={getLanguageVisits.data} />
                                        </div>
                                    </Card.Body>}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* <!-- ROW-1 END --> */}

        </div>
    )
}

export default HabitsGeneralUX;
