import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'rsuite/dist/rsuite.min.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import Loader from './Layouts/Loader/Loader';
import "./index.scss"
import 'mapbox-gl/dist/mapbox-gl.css';
import './assets/css/devChanges.css';
import reportWebVitals from './reportWebVitals';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";
import Router from './routes/router'

Chart.register(...registerables, ChartDataLabels);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Fragment>
        <BrowserRouter>
          <React.Suspense fallback={<Loader />}>
            <Router />
          </React.Suspense>
        </BrowserRouter>
      </Fragment>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
