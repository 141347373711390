import { ActionType } from '../action-types';
import { Action } from '../actions/index'

const initialState = false;

const reducer = ( state: boolean = initialState , action: Action ) => {
    switch (action.type) {
        case ActionType.UPDATE_IS_AUTHENTICATED:
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default reducer;