/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import validateFilters from "utils/validateFilters";
import axios from "axios";
import { useSelector } from "react-redux";
import { State } from "store/reducers";

const useFetch = ({ request, defaultData }, options) => {
  const [data, setData] = useState<any>(defaultData);
  const [loading, setLoading] = useState<boolean>(false);
  const museumList: any = useSelector((state: State) => state.museumList);

  const getDataFromAxios = useCallback(
    async (cancelToken) => {
      try {
        if (options.floor === null) {
          return;
        }
        setLoading(true);
        const response = await request({
          params: validateFilters(options, museumList),
          cancelTokenSource: cancelToken,
        });
        setData(response);
      } catch (error) {
        console.log("error useFetch", error);
      } finally {
        setLoading(false);
      }
    },
    [options, request]
  );

  const cancelToken = useCallback(() => {
    try {
      return axios.CancelToken.source();
    } catch (error) {
      console.log("error cancelToken", error);
    }
  }, []);

  useEffect(() => {
    try {
      let source = cancelToken();
      getDataFromAxios(source!.token);
      return () => {
        source!.cancel("Request was canceled by unmounting");
      };
    } catch (error) {
      console.log("error:", error);
    }
  }, [cancelToken, getDataFromAxios]);

  return { data, loading };
};

export default useFetch;
