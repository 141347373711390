import React, { useState } from 'react';
import styles from './scss/Dashboard.module.scss';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import { TotalUser } from '../../Data/DataDashBoard/DashBoardData';
import { TComponentUX } from './types/TServicesExploitation';
import { DonutApexChart } from './components/DonutApexChart'
import { DnaLoader } from 'components/loaders/DnaLoader';
import { FilterSelector } from "components/filters/Filter";
import { Card, Col, Row, ListGroup } from 'react-bootstrap';
import { ResponsiveDataTable } from 'Data/Pages/TablesData/TableData';
import Select from 'react-select';
import Map, { LngLatBoundsLike, Marker, Popup } from 'react-map-gl';
import point from 'assets/images/point.png'
import floor1 from 'assets/images/floor1.png'
import floor2 from 'assets/images/floor2.png'
import floor3 from 'assets/images/floor3.png'
import floor4 from 'assets/images/floor4.png'

const SpaceInteractionUX: React.FC<TComponentUX> = ({ getVisitByUsersData, getArtworkMostConsulted, setFloor, setHall, downloadPDF, floor, hall }) => {

    const plantas = [{ id: 0, label: 'Planta 1' }, { id: 1, label: 'Planta 2' }, { id: 2, label: 'Planta 3' }]
    const [showPopup, setShowPopup] = useState<any>(null);
    const bounds: LngLatBoundsLike = [-3.700, 40.404, -3.686, 40.410]

    const markers = [{
        id: 203,
        longitude: -3.693664,
        latitude: 40.407902,
        title: 'La santa bohemia. Madrid, París, Barcelona',
        image: floor1
    }, {
        id: 204,
        longitude: -3.69457769,
        latitude: 40.40866942,
        title: 'Ciudades modelo, nueva arquitectura',
        image: floor2
    }, {
        id: 205,
        longitude: -3.69482122,
        latitude: 40.40803649,
        title: 'Mujeres en vanguardia',
        image: floor3
    }, {
        id: 206,
        longitude: -3.69326896,
        latitude: 40.40817564,
        title: 'Realismo y superrealismo en el arte nuevo',
        image: floor4
    }]

    return (
        <div className={styles.Dashboard}>
            <PageHeader titles="Interacción con el Espacio" active="Dashboard" items={['Home']} downloadPDF={downloadPDF} />
            {/* <!-- ROW-1 --> */}
            <FilterSelector /> <br />
            <Row>
                <Col lg={12} md={12} sm={12} xl={12}>
                    <Row>
                        <Col lg={6} md={6} sm={12} xl={3}>
                            <Card className="card">
                                <Card.Body>
                                    {plantas.length &&
                                        <ListGroup className='py-2'>
                                            <h4>Seleccione Planta</h4>
                                            <Select
                                                className="basic-single"
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 4,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: 'orangered',
                                                        primary25: '#5348C2',
                                                        primary: '#1e1a47',
                                                    },
                                                })}
                                                classNamePrefix="select-default"
                                                isDisabled={false}
                                                isLoading={false}
                                                isRtl={false}
                                                isSearchable={true}
                                                onChange={(e: any) => { setHall(null); setFloor(e.value) }}
                                                name="color"
                                                placeholder="Seleccione una planta
                                                "
                                                options={plantas.map((e) => {
                                                    return {
                                                        value: e.id, label: e.label,
                                                    }
                                                })}
                                            /></ListGroup>}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12} xl={3}>
                            <Card className="card overflow-hidden table-button-dev">
                                {getVisitByUsersData.loading ?
                                    <DnaLoader /> :
                                    <>
                                        <Card.Body id="graphic-spaceInteraction-1">
                                            <div className="d-flex" >
                                                <div className="mt-2">
                                                    <h6 className="">Tiempo Medio</h6>
                                                    <h2 className="mb-0 number-font">0</h2>
                                                </div>
                                                <div className="ms-auto">
                                                    <div className="chart-wrapper mt-1 p-1">
                                                        <TotalUser />
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </>}
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12} xl={3}>
                            <div className="card overflow-hidden table-button-dev">
                                {getVisitByUsersData.loading ?
                                    <DnaLoader /> :
                                    <>
                                        <Card.Body id="graphic-spaceInteraction-2">
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className="">Número de Obras</h6>
                                                    <h2 className="mb-0 number-font">0</h2>
                                                </div>
                                                <div className="ms-auto">
                                                    <div className="chart-wrapper mt-1">
                                                        <DonutApexChart data={[getVisitByUsersData.data.easyGuide, getVisitByUsersData.data.smartGuide]} type="easy" width={110} height={80} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </>}
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xl={3}>
                            <div className="card overflow-hidden table-button-dev">
                                {getVisitByUsersData.loading ? <DnaLoader /> :
                                    <Card.Body id="graphic-spaceInteraction-3">
                                        <div className="d-flex">
                                            <div className="mt-2">
                                                <h6 className="">Tiempo Medio</h6>
                                                <h2 className="mb-0 number-font">0</h2>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="chart-wrapper mt-1">
                                                    <DonutApexChart data={[getVisitByUsersData.data.smartGuide, getVisitByUsersData.data.easyGuide]} type="smart" width={110} height={80} />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>}
                            </div>
                        </Col>
                    </Row>
                </Col>
                {/* <Col lg={12} md={12} sm={12} xs={12} xl={12}>
                    {getArtworkMostConsulted.loading ? <DnaLoader /> :
                        <>
                            <div className="card overflow-hidden">
                                <Card.Body id="graphic-spaceInteraction-4">
                                    <Card.Title className='m-2' as="h3">Artes más consultados</Card.Title>
                                    <div className="table-responsive">
                                        <ResponsiveDataTable data={getArtworkMostConsulted.data} />
                                    </div>
                                </Card.Body>
                            </div>
                        </>}
                </Col> */}
                {/* <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                    <Card>
                        {getArtworkMostConsulted.loading ?
                            <DnaLoader /> :
                            <>
                                {(getArtworkMostConsulted.data.body !== undefined) && <> <Card.Header>
                                    <Card.Title as="h3">Salas</Card.Title>
                                </Card.Header>
                                    <Card.Body>
                                        <div className="chartjs-wrapper-demo myChartSaah text-center">
                                            <ReactLogo className="svg-interaction" onClick={(e: any) => {
                                                if (e.target.id !== 'svg-input') {
                                                    setHall(e.target.parentNode.id || e.target.parentElement.id)
                                                }
                                            }} id="svg-input" />
                                        </div>
                                    </Card.Body></>}
                            </>}
                    </Card>
                </Col> */}
                <Col xs={12}>
                    {(floor !== null || hall !== null) &&
                        <>
                            <div className='modal-mapbox'>
                                    <div className="card overflow-hidden">
                                        <Card.Body style={{ paddingLeft: 0, paddingRight: 0 }} id="graphic-spaceInteraction-4">
                                            <Card.Title className='m-2 text-center' as="h3">{hall !== null ? <>Interacción con las salas </> : <>Interacción con las Plantas </>}</Card.Title>
                                            {getArtworkMostConsulted.loading ? <DnaLoader /> :
                                                <div className="table-responsive">
                                                    <ResponsiveDataTable data={getArtworkMostConsulted.data} />
                                                </div>
                                            }
                                        </Card.Body>
                                    </div>
                            </div>
                            <Map
                                mapboxAccessToken="pk.eyJ1IjoiZGFya2lhbjk4IiwiYSI6ImNsZmd1Mm0waTA3Nzgzb3JzajQwajRsbXQifQ.5sZVjjNRPaCk5QZFkINizg"
                                initialViewState={{
                                    latitude: 40.407902,
                                    longitude: -3.693664,
                                    zoom: 17,
                                }}
                                style={{ width: '100%', height: '70vh' }}
                                mapStyle="mapbox://styles/darkian98/clfo7lfit005101nn90kljyqp"
                                maxZoom={18}
                                minZoom={15}
                                doubleClickZoom
                                maxBounds={bounds}
                            >
                                {markers && markers.map((item, i) => {
                                    return (
                                        <Marker key={i + Math.random() * 100} onClick={e => {
                                            e.originalEvent.stopPropagation();
                                            setShowPopup(item)
                                            setHall(item.id);
                                        }} style={{ cursor: 'pointer' }} longitude={item.longitude} latitude={item.latitude} anchor="right" >
                                            <img style={{ height: 40, width: 40 }} src={point} alt="" />
                                        </Marker>
                                    )
                                })}
                                {/* <FullscreenControl /> */}
                                {(showPopup !== null) &&
                                    <Popup style={{ color: 'black' }} longitude={showPopup.longitude} latitude={showPopup.latitude}
                                        anchor="bottom"
                                        onClose={e => setShowPopup(null)}
                                    >
                                        <div className='popup-title'>{showPopup.title}</div>
                                        <img className='popup-image' style={{ width: '100%', height: '100%' }} src={showPopup.image} alt="" />
                                        {/* <button onClick={e => { setFloor(showPopup.id); document.fullscreen && document.exitFullscreen(); setShowPopup(null) }} className='popup-button'>Filtrar</button> */}
                                    </Popup>}
                            </Map>
                        </>}
                </Col>
            </Row>
            {/* <!-- ROW-1 END --> */}


        </div>
    )
}

export default SpaceInteractionUX;
