import axios from "../axios";

export const getMuseum = () => {

  return axios
    .get(`/museums`)
    .then(({ data }) => Promise.resolve(data.museums['hydra:member']))
    .catch((error) => {
      return Promise.reject(error);
    });
};
