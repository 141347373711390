import useFetch from "hooks/useFetch";
import { RequestFetchVisitByUser } from "./helpers/RequestFetchVisitByUser"
import SpaceInteractionUX from "./SpaceInteractionUX"
import { useDispatch, useSelector } from 'react-redux';
import { State } from "store/reducers";
import { RequestArtworkMostConsulted } from "./helpers/RequestArtworkMostConsulted";
import { useMemo, useState } from "react";
import { handleGeneratePdfAllPage } from "./helpers/SpaceInteractionAllPdf";


export const Productivity: React.FC = () => {

    const dispatch = useDispatch();
    const [floor, setFloor] = useState<any>(null);
    const [hall, setHall] = useState<any>(null);
    const [downloadPDF] = useState<any>(() => () => handleGeneratePdfAllPage(dispatch));
    const filters: any = useSelector((state: State) => state.filters);
    const filterModified: any = useMemo(() => {
        const newew = { ...filters }
        newew.floor = floor
        newew.idHall = hall
        return newew
    }, [filters, floor, hall]);

    const getVisitByUsersData = useFetch(RequestFetchVisitByUser(), filterModified);
    const getArtworkMostConsulted = useFetch(RequestArtworkMostConsulted(), filterModified);

    return (
        <>
            <SpaceInteractionUX
                getVisitByUsersData={getVisitByUsersData}
                setFloor={setFloor}
                setHall={setHall}
                downloadPDF={downloadPDF}
                getArtworkMostConsulted={getArtworkMostConsulted}
                floor={floor} 
                hall={hall}/>
        </>
    )
}


export default Productivity;

