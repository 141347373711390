import { parseDataTotalHours } from "../../adapters/parserDataTotalHours";
import axios from "../axios";

export const getTotalHours = ({params, cancelTokenSource}) => {

  return axios
    .get(`/most_visited_times`, {
      cancelToken: cancelTokenSource,
      params
    })
    .then(({ data }) => {
      const dataParsed = parseDataTotalHours(data.visits['hydra:member']);
      return dataParsed;
    } )
    .catch((error) => {
      return Promise.reject(error);
    });
};
