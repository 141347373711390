import axios from "../axios";

export const getVisitsByMuseum = ({params, cancelTokenSource}) => {

  return axios
    .get(`/visits_by_museum`, {
      cancelToken: cancelTokenSource,
      params
    })
    .then(({ data }) => Promise.resolve(data))

    .catch((error) => {
      return Promise.reject(error);
    });
};
