const TableMostVisitedArtwork = (dataIn: any) => {

  const data = {
    headers: [
      {
        Header: "Título",
        accessor: "title",
        className: "text-center wd-15p border-bottom-0 ",
        toolTip: "Titulo",
        Cell: row => (<div className="tooltip-table" data-toggle="tooltip" data-placement="top" title={row.value} style={{ textAlign: "left" }}>{row.value}</div>),
      },
      {
        Header: "Autor",
        accessor: "autor",
        className: "text-center border-bottom-0 ",
        toolTip: "Autor",
        Cell: row => (<div className="tooltip-table" data-toggle="tooltip" data-placement="top" title={row.value} style={{ textAlign: "left" }}>{row.value}</div>),
      },
      {
        Header: "N.V",
        accessor: "visits",
        toolTip: "Número de Visitas",
        className: "text-center wd-15p border-bottom-0 ",
        Cell: row => (<div style={{ textAlign: "right" }}><div>{row.value.toLocaleString("de", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div></div>),
      },
    ],
    body: dataIn
  };
  return data;
};

export default TableMostVisitedArtwork;