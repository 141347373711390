import * as XLSX from "xlsx";;

export const downloadByDevice = (data, nameMuseum, startDate, endDate) => {
    try {
        if (data) {
            const datos = data.map((it) => {
                const { device, month, route, visits } = it;
                return {
                    month,
                    route,
                    visits,
                    device,
                };
            });

            const ws = XLSX.utils.json_to_sheet(datos);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Reporte");
            XLSX.utils.sheet_add_aoa(
                ws,
                [["Fecha", "Ruta", "Visitas", "Dispositivos"]],
                {
                    origin: "A1",
                }
            );
            const filename = nameMuseum + "-byDevice-" +
                (new Date(startDate)).toISOString().split('T')[0].replaceAll("-", "") +
                "-" +
                (new Date(endDate)).toISOString().split('T')[0].replaceAll("-", "") +
                ".xlsx";
            XLSX.writeFile(wb, filename);
        } else {
            console.log("SIN DATOS");
        }
    } catch (error) {
        console.error(error);
    }
}