import { Dna } from "react-loader-spinner"

export const DnaLoader = () => {
    return (
        <>
            <div className='mt-5'>
                <Dna
                    visible={true}
                    height="78"
                    width="100% "
                    ariaLabel="dna-loading"
                    wrapperStyle={{}}
                    wrapperClass="dna-wrapper"
                />
            </div>
        </>
    )
}

