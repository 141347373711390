import { Bar } from 'react-chartjs-2';

const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
        padding: {
          bottom: 20,
          color: 'black'
        }
      },
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
      },
      datalabels: {
        display: false,
      },
    },
  };

export const BarChartPDF = ({data}) => {
    return <Bar height={1080} width={1920} options={options} data={data} />;
  }
