import { getLanguageVisits } from "services/api";
import { TFetchRequest } from "../types/TServicesExploitation";
import { TFetchResponseArtworkMostConsulted } from "../types/TServicesExploitation";


export const RequestArtworkMostConsulted = (): TFetchRequest => {

  const defaultData: TFetchResponseArtworkMostConsulted = {
    data: {
      headers: [
        {
          Header: "Imagen",
          accessor: "urlImage",
          className: "text-center wd-15p border-bottom-0 ",
          Cell: "No data available",
          toolTip: ""
        },
        {
          Header: "Título",
          accessor: "title",
          className: "text-left wd-15p border-bottom-0 ",
          toolTip: ""
        },
        {
          Header: "N. visitas",
          accessor: "visits",
          className: "text-right wd-15p border-bottom-0 ",
          toolTip: ""
        },
      ],
      body: [
        {
          urlImage: "",
          artname: "",
          artwork_visited: ""
        },
      ],
    },
    loading: false,
  };

  return { request: getLanguageVisits, defaultData };
};
