import useFetch from "hooks/useFetch";
import { RequestArtworkMostConsulted } from "./helpers/RequestArtworkMostConsulted"
import InterestsGeneralUX from "./InterestsGeneralUX"
import { useDispatch, useSelector } from 'react-redux';
import { State } from "store/reducers";
import { handleGeneratePdfAllPage } from "./helpers/InterestGeneralAllPdf";
import { useState } from "react";

export const InterestsGeneral: React.FC = () => {

    const dispatch = useDispatch();
    const filters = useSelector((state: State) => state.filters);

    const [downloadPDF] = useState<any>(() => () => handleGeneratePdfAllPage(dispatch));
    const getArtworkMostConsulted = useFetch(RequestArtworkMostConsulted(), filters);

    return (
        <>
            <InterestsGeneralUX getArtworkMostConsulted={getArtworkMostConsulted} downloadPDF={downloadPDF}/>
        </>
    )   
}


export default InterestsGeneral;
