import { getAverageDurationVisit } from "services/api"
import { TFetchRequest } from "pages/productivity/types/TServicesExploitation"
import { TRequestResponse } from "../types/TServicesExploitation"

export const RequestFetchAverageDurationVisit = (): TFetchRequest =>  {
    const defaultVisitByUser: TRequestResponse = { all: 0,
        smartGuide: 0,
        easyGuide: 0
    }
    return {request: getAverageDurationVisit, defaultData: defaultVisitByUser}
}