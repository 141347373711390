import { ProgressBar } from "react-bootstrap";
import { getArtworkMostConsulted } from "services/api";
import { TFetchRequest } from "../types/TServicesExploitation";
import { TFetchResponseArtworkMostConsulted } from "../types/TServicesExploitation";


export const RequestArtworkMostConsulted = (): TFetchRequest => {

  const defaultData: TFetchResponseArtworkMostConsulted = {
    data: {
      headers: [
        {
          Header: "Imagen",
          accessor: "urlImage",
          className: "text-center wd-15p border-bottom-0 ",
          toolTip: "...",
          Cell: "No data available",
        },
        {
          Header: "Título",
          accessor: "title",
          className: "text-center wd-15p border-bottom-0 ",
          toolTip: "...",

        },
        {
          Header: "Autor",
          accessor: "autor",
          className: "text-center wd-15p border-bottom-0 ",
          toolTip: "...",

        },
        {
          Header: "Porcentaje de Reproducción",
          accessor: "percentage_visit",
          className: "text-center wd-15p border-bottom-0 ",
          toolTip: "...",
          Cell: row => (<ProgressBar variant="purple" className="mb-3" now={0} label={`${0}%`} />),
        },
        {
          Header: "N. visitas",
          accessor: "visits",
          className: "text-center wd-15p border-bottom-0 ",
          toolTip: "...",
        },
      ],
      body: [
        {
          urlImage: "",
          artname: "",
          p_visit: "",
          artwork_visited: ""
        },
      ],
    },
    loading: false,
  };

  return { request: getArtworkMostConsulted, defaultData };
};
