import { FC } from "react";
import { ISelectorOption, TListOption } from "../types";

export const SelectorOption: FC<ISelectorOption> = ({ handler, optionSelected }) => {
    const listOption: TListOption = [
        { id: 'device', label: 'Dispositivos' },
        { id: 'languages', label: 'Lenguajes' },
    ]
    return (
        <section className="selector-data mt-5">
            {listOption.map((option) => {
                const { id, label } = option;
                return (
                    <button key={'selection' + id} onClick={() => handler(id)} className={`button-VA py-2 ${optionSelected === id && 'active'}`}>{label}</button>
                )
            })
            }
        </section>
    )
}