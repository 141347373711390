import * as React from 'react';
import { useSelector } from "react-redux";
import { State } from "store/reducers";
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import Loader from 'Layouts/Loader/Loader';

type Props = {
    children?: React.ReactNode,
    render: any
};

const PrivateRoute: React.FC<Props> = ({ children, render: Render }) => {

    const isAuthenticated: boolean = useSelector((state: State) => state.isAuthenticated);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated)
            navigate("/")
    }, [isAuthenticated, navigate])

    return (
        <>
            {isAuthenticated? <Render />: <Loader />}
        </>
    )


}

export default PrivateRoute