import { ActionType } from '../action-types';
import { Action } from '../actions/index'

const initialState = [];

const reducer = ( state: any = initialState , action: Action ) => {
    switch (action.type) {
        case ActionType.UPDATE_MUSEUM:
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default reducer;