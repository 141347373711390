import useFetch from "hooks/useFetch";
import { RequestFetchAverageDurationVisit } from "./helpers/RequestFetchAverageDurationVisit"
import { RequestAvgNumWorkConsumedVisitor } from "./helpers/RequestAvgNumWorkConsumedVisitor"
import { RequestAvgTimeConsumedArtVisitor } from "./helpers/RequestAvgTimeConsumedArtVisitor"
import HabitsGeneralUX from "./HabitsGeneralUX"
import { useDispatch, useSelector } from 'react-redux';
import { State } from "store/reducers";
import { useState } from "react";
import { handleGeneratePdfAllPage } from "./helpers/HabitsGeneralAllPdf";
import { RequestArtworkMostConsulted } from "../interestsGeneral/helpers/RequestArtworkMostConsulted"

export const HabitsPublic: React.FC = () => {

    const dispatch = useDispatch();
    const filters = useSelector((state: State) => state.filters);
    const [downloadPDF] = useState<any>(() => () => handleGeneratePdfAllPage(dispatch));

    const getAverageDurationVisit = useFetch(RequestFetchAverageDurationVisit(), filters);
    const getAvgNumWorkConsumedVisitor = useFetch(RequestAvgNumWorkConsumedVisitor(), filters);
    const getAvgTimeConsumedArtVisitor = useFetch(RequestAvgTimeConsumedArtVisitor(), filters);
    const getLanguageVisits = useFetch(RequestArtworkMostConsulted(), filters);    
    
    return (
        <>
            <HabitsGeneralUX
                getAverageDurationVisit={getAverageDurationVisit}
                getAvgNumWorkConsumedVisitor={getAvgNumWorkConsumedVisitor}
                getAvgTimeConsumedArtVisitor={getAvgTimeConsumedArtVisitor} 
                getLanguageVisits={getLanguageVisits}
                downloadPDF={downloadPDF} />
        </>
    )
}


export default HabitsPublic;
